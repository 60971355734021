import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import SEO from '../../components/SEO';
import Body from '../../components/layout/Body';
import ResponsiveContainer from '../../components/layout/ResponsiveContainer';
import ContentWrapper from '../../components/layout/ContentWrapper';
import TextHeadingWrapper from '../../components/layout/TextHeadingWrapper';

import PricingPackageFeature from '../../components/frills/PricingPackageFeature';

import { ViewportBreakpoints } from '../../components/cssConstants';

import SchedulingIcon from '../../images/svg/icons/feature-rota-scheduling.svg';
import TimeAttendanceIcon from '../../images/svg/icons/feature-time-attendance.svg';
import HrIcon from '../../images/svg/icons/feature-hr.svg';
import PayrollIcon from '../../images/svg/icons/feature-payroll.svg';
import IntegrationsIcon from '../../images/svg/icons/feature-integrations.svg';

import H1 from '@rotaready/frecl/build/H1';
import H2 from '@rotaready/frecl/build/H2';
import H3 from '@rotaready/frecl/build/H3';
import Text from '@rotaready/frecl/build/Text';
import Button from '@rotaready/frecl/build/Button';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const featureComparison = [
  {
    category: 'Rota scheduling',
    features: [
      { label: 'Advanced drag-and-drop Rota Editor' },
      { label: 'Automated rota scheduling' },
      { label: 'Automatic application of pay and break policies' },
      { label: 'Budgeting' },
      { label: 'Custom sales and revenue streams' },
      { label: 'Forecast vs actual, for sales and wages' },
      { label: 'Live labour calculations' },
      { label: 'Live validation of every change' },
      { label: 'Local 10-day weather forecast' },
      { label: 'Rota approval process' },
      { label: 'Shift Broadcast' },
      { label: 'Shift swaps' },
      { label: 'Working Time regulations compliance' },
      { label: 'Audit history of all changes' },
      { label: 'Notable events', byPackage: [false, true, true] },
    ],
  },
  {
    category: 'HR',
    features: [
      { label: 'Employee records' },
      { label: 'Chronological employment, role and pay history' },
      { label: 'Absence/time off' },
      { label: 'Holiday allowances' },
      { label: 'Allowances for any absence type', byPackage: [false, true, true] },
      { label: 'Availability patterns' },
      { label: 'Document storage (unlimited)' },
      { label: 'Document signatures (fully legal e-signature)', byPackage: [false, true, true] },
      { label: 'Document acknowledgements (by employees)', byPackage: [false, true, true] },
      { label: 'Emergency contacts' },
      { label: 'GDPR compliant' },
      { label: 'Audit history of all changes' },
      { label: 'Fully flexible permissions' },
      { label: 'Asset registry', byPackage: [false, true, true] },
      { label: 'Benefits tracker', byPackage: [false, true, true] },
      { label: 'Customisable onboarding for new joiners via the Rotaready app, capturing key details and documents', byPackage: [false, true, true] },
      { label: 'Allow employees to submit changes to their address, bank details and more', byPackage: [false, true, true] },
    ],
  },
  {
    category: 'Time & attendance',
    features: [
      { label: 'Clock-in via GPS, PIN code or photo capture' },
      { label: 'Automatic issue detection' },
      { label: 'Attendance automatically matched with shifts' },
      { label: 'Automatic holiday accrual' },
      { label: 'Automatic \'rounding\' with customisable rules' },
      { label: 'Overtime tracking and approval' },
      { label: 'Audited sign-off process' },
      { label: 'Audit history of all changes' },
      { label: 'Instant export to payroll' },
    ],
  },
  {
    category: 'Cost control',
    features: [
      { label: 'Track wages, hours, sales, wage percentage, covers, productivity' },
      { label: 'Forecast vs actual vs budget' },
      { label: 'Audited sign-off process' },
      { label: 'Covers per labour hour', byPackage: [false, true, true] },
      { label: 'Compare figures at a glance to any past week or year', byPackage: [false, true, true] },
      { label: 'Track custom metrics', byPackage: [false, true, true] },
      { label: 'Tronc', byPackage: [false, false, false] },
    ],
  },
  {
    category: 'Payroll',
    features: [
      { label: 'Perfect timesheets, with overtime, breaks & more' },
      { label: 'Export timesheets to 3rd party payroll providers' },
      { label: 'Out-of-the-box integrations with many payroll systems' },
      { label: 'Seamless 2-way communication' },
      { label: 'Fully managed payroll bureau (at extra cost)' },
    ],
  },
  {
    category: 'Integrations & SSO',
    features: [
      { label: 'Free integrations with EPOS, Reservations, Payroll, Applicant Tracking (ATS), Property Management (PMS), Training, Communications and more' },
      { label: 'Open API and Webhooks', byPackage: [false, false, true] },
      { label: 'Sign-in to Rotaready using SSO providers like Google and Azure', byPackage: [false, false, true] },
      { label: 'Sign-in to your other systems and tools using "Sign-in with Rotaready" (OIDC)', byPackage: [false, false, true] },
    ],
  },
  {
    category: 'Demand forecasting',
    features: [
      { label: 'Accurate sales predictions', byPackage: [false, false, true] },
      { label: 'Aggregated weather, reservations, notable events & more', byPackage: [false, false, true] },
      { label: 'Historical sales and transactions', byPackage: [false, false, true] },
      { label: 'Pooling technology to leverage industry peer data', byPackage: [false, false, true] },
      { label: 'Deep learning for automatic trend detection', byPackage: [false, false, true] },
      { label: 'Local events, national holidays and custom events', byPackage: [false, false, true] },
      { label: 'No need for in-person observation studies or consultancy', byPackage: [false, false, true] },
    ],
  },
  {
    category: 'Messaging',
    features: [
      { label: 'Real-time chat', byPackage: [false, true, true] },
      { label: 'Group conversations', byPackage: [false, true, true] },
      { label: 'Messaging between sites', byPackage: [false, true, true] },
    ],
  },
  {
    category: 'Internationalisation',
    features: [
      { label: 'Multiple languages' },
      { label: 'Multiple timezones', byPackage: [false, false, true] },
      { label: 'Multiple currencies', byPackage: [false, false, true] },
    ],
  },
  {
    category: 'Other',
    features: [
      { label: 'Rotaready accounts for all employees' },
      { label: 'iPhone and Android mobile apps' },
      { label: 'Cloud based' },
      { label: 'Unlimited email, SMS & push notifications' },
      { label: 'Comprehensive reporting & exports' },
    ],
  },
];

const Hero = styled.div`
  padding: 80px 0 40px 0;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    padding: 120px 0 60px 0;
  }
`;

const HeroBody = styled.div`
  text-align: center;
  margin: 0 auto;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    width: 50%;
  }
`;

const Calculator = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 960px;
  margin: 60px auto 80px auto;
  padding: 20px;

  border: 1px solid ${({ theme: { colors } }) => colors.grey20};
  border-radius: 6px;
  box-sizing: border-box;
  box-shadow: 0 0 4px 1px rgba(71, 91, 104, 0.1);

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    padding: 40px 20px;
  }
`;

const PriceComponents = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    flex-direction: row;
    justify-content: space-evenly;
  }
`;

const PriceBreakdown = styled(Text).attrs({
  size: 'md',
})`
  align-self: center;
  color: ${({ theme: { colors } }) => colors.grey60};

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    margin-top: 20px;
  }
`;

const PriceComponent = styled.div`
  flex: 0 1 auto;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    flex: 0 1 300px;
    padding: 0;
  }
`;

const PriceConfigurable = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
  width: 100%;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    margin: 40px 0;
  }
`;

const CenteredWrapper = styled.div`
  margin: 0 auto;
  text-align: center;
`;

const SpinnerButton = styled(Button).attrs({
  uistyle: 'primary',
  size: 'md',
})`
  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    margin: 0 10px;
  }
`;

const BorderlessInput = styled.input`
  font-family: ${({ theme: { fonts } }) => fonts.Body1.fontFamily};
  font-size: 38px;
  font-weight: 600;
  color: ${({ theme: { colors } }) => colors.brand160};
  line-height: 54px;
  text-align: center;
  border: none;
  width: 90px; // 100% works on Chrome but breaks Firefox

  &:focus {
    outline: 0;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type=number] {
    -moz-appearance: textfield;
  }
`;

const PackageContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 40px 0 0 0;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    flex-direction: row;
    height: 100%;
  }
`;

const Package = styled.div`
  flex: 1;
  background-color: ${({ theme: { colors } }) => colors.grey10};
  border-radius: 6px;
  margin: 20px 0;
  padding: 40px 20px 20px 20px;
  position: relative;
  text-align: center;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    margin: 0 20px;
    padding: 40px 23px 20px 23px;
  }
`;

const PackageItemsColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PackageItemsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const PackageHighlight = styled.div`
  position: absolute;
  border-radius: 3px;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
`;

const PackageHighlightLarge = styled(PackageHighlight)`
  padding: 9px 22px;
  background-color: ${({ theme: { colors } }) => colors.info};
  top: -20px;
`;

const PackageHighlightSmall = styled(PackageHighlight)`
  padding: 4px 10px;
  background-color: ${({ theme: { colors } }) => colors.support};
  top: -15px;
`;

const PackageTitle = styled(H3).attrs({
  uistyle: 'brand160',
})`
  margin: 0 0 10px 0;
  font-weight: bold;
`;

const Price = styled(H1)``;
const PriceSubText = styled(H3)`
  margin: 0 0 10px 0;

  sup {
    font-size: 14px;
    top: -2px;
    color: ${({ theme: { colors } }) => colors.grey80};
  }
`;

const PackageText = styled(Text).attrs({
  size: 'xl'
})`
  margin-bottom: 20px;
`;

const ButtonBox = styled.div`
  text-align: center;

  button {
    margin: 10px;
  }
`;

const ComparisonTableWrapper = styled.div`
  overflow-x: scroll;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    overflow: visible;
  }
`;

const ComparisonTable = styled.table`
  width: 100%;
  border-spacing: 0;
  margin-bottom: 80px;

  thead tr th {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: white;
  }

  tr > td:first-child {
    text-align: left;
    width: 40%;
  }

  tr > td:not(:first-child) {
    width: 20%;
  }

  tr > td, tr > th {
    text-align: center;
    padding: 20px;
  }

  tr {
    td:not(:first-child), th:not(:first-child) {
      border-left: 1px solid ${({ theme: { colors } }) => colors.grey40};
    }
  }

  tr.bordered {
    td {
      border-bottom: 1px solid ${({ theme: { colors } }) => colors.grey40};
    }
  }
`;

const CellText = styled(Text).attrs({
  size: 'xl',
})``;

const HeaderRow = styled.tr`
  td {
    background-color: ${({ theme: { colors } }) => colors.grey10};
  }

  td:first-child {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }

  td:last-child {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }
`;

const SetupFeeContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
`;

const SetupFeeItem = styled.div`
  width: calc(25% - 40px);
  padding: 22px 44px;
  display: flex;
  flex-direction: row;
  background-color: ${({ theme: { colors } }) => colors.grey10};
  border-radius: 6px;
  margin: 20px;
  flex: 1 1 360px;
`;

const SetupFeeItemIcon = styled.div`
  margin-right: 38px;
`

const SetupFeeItemText = styled(H3).attrs({
  uistyle: 'brand160',
})``;

const CheckmarkIcon = styled(FontAwesomeIcon).attrs({
  icon: ['fal', 'check'],
})`
  color: ${({ theme: { colors } }) => colors.success};
  font-size: 24px;
`;

const TrainingSessionIcon = styled(FontAwesomeIcon).attrs({
  icon: ['fal', 'user-chart'],
})`
  color: ${({ theme: { colors } }) => colors.info};
  font-size: 34px;
`;

const AnnualReviewIcon = styled(FontAwesomeIcon).attrs({
  icon: ['fal', 'tools'],
})`
  color: ${({ theme: { colors } }) => colors.success};
  font-size: 34px;
`;

const FullSetupIcon = styled(FontAwesomeIcon).attrs({
  icon: ['fal', 'calendar-check'],
})`
  color: ${({ theme: { colors } }) => colors.brand};
  font-size: 34px;
`;

const ConsultatonIcon = styled(FontAwesomeIcon).attrs({
  icon: ['fal', 'comment-alt-dots'],
})`
  color: ${({ theme: { colors } }) => colors.support};
  font-size: 34px;
`;

const header = {
  backgroundColor: 'white',
  invertColors: false,
};

class PricingPage extends React.Component {
  constructor(props) {
    super(props);

    this.defaults = {
      freePeoplePerSite: 15,
      pricePerSiteEssentials: 89,
      pricePerSitePro: 109,
      pricePerPersonEssentials: 4,
      pricePerPersonPro: 4.50,
      siteLimit: 15,
      personLimit: 500,
      initialSites: 1,
      initialPeople: 15,
    };

    this.state = {
      sites: this.defaults.initialSites,
      people: this.defaults.initialPeople,
      priceEssentials: 0,
      pricePro: 0,
      outOfScope: false,
      freePeople: 0,
      extraPeople: 0,
    };
  }

  componentDidMount() {
    this.calculatePrice();
  }

  onSitesChange = (event) => {
    const sites = event.target.value;

    this.setState(() => ({ sites }), () => {
      this.calculatePrice();
    });
  };

  onPeopleChange = (event) => {
    const people = event.target.value;

    this.setState(() => ({ people }), () => {
      this.calculatePrice();
    });
  };

  incrementValue = propName => () => {
    const { [propName]: rawValue } = this.state;
    const value = parseInt(rawValue, 10);

    if (Number.isNaN(value)) {
      return;
    }

    this.setState(() => ({ [propName]: value + 1 }), () => {
      this.calculatePrice();
    });
  };

  decrementValue = propName => () => {
    const { [propName]: rawValue } = this.state;
    const value = parseInt(rawValue, 10);

    if (Number.isNaN(value) || value <= 1) {
      return;
    }

    this.setState(() => ({ [propName]: value - 1 }), () => {
      this.calculatePrice();
    });
  };

  handleFocus = event => event.target.select();

  formatAsSterling = (number) => {
    const fraction = new Intl.NumberFormat('en-GB', {
      style: 'currency',
      currency: 'GBP',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });

    const formatter = new Intl.NumberFormat('en-GB', {
      style: 'currency',
      currency: 'GBP',
      minimumFractionDigits: 2,
    });

    if (number % 1 === 0) {
      return fraction.format(number);
    }

    return formatter.format(number);
  };

  calculatePrice = () => {
    const {
      sites: sitesRaw,
      people: peopleRaw,
    } = this.state;

    const sites = parseInt(sitesRaw, 10);
    const people = parseInt(peopleRaw, 10);

    if (Number.isNaN(sites) || !sites || Number.isNaN(people) || !people) {
      return;
    }

    const {
      freePeoplePerSite,
      pricePerSiteEssentials,
      pricePerSitePro,
      pricePerPersonEssentials,
      pricePerPersonPro,
      siteLimit,
      personLimit,
    } = this.defaults;

    const outOfScope = sites > siteLimit || people > personLimit;

    const freePeople = freePeoplePerSite * sites;
    let priceEssentials = pricePerSiteEssentials * sites;
    let pricePro = pricePerSitePro * sites;
    let extraPeople = 0;

    if (people > freePeople) {
      extraPeople = people - freePeople;
      priceEssentials += (pricePerPersonEssentials * extraPeople);
      pricePro += (pricePerPersonPro * extraPeople);
    }

    this.setState(() => ({
      priceEssentials: this.formatAsSterling(priceEssentials),
      pricePro: this.formatAsSterling(pricePro),
      outOfScope,
      freePeople,
      extraPeople,
    }));
  };

  render() {
    const {
      pricePerPersonEssentials,
      pricePerPersonPro,
    } = this.defaults;

    const {
      sites,
      people,
      priceEssentials,
      pricePro,
      outOfScope,
    } = this.state;

    return (
      <Body header={header}>
        <SEO
          title="Pricing - Rotaready"
          description="Pricing for our rota scheduling software for hospitality, leisure and retail. Get a quick estimate using our online calculator."
          url="pricing"
        />

        <ResponsiveContainer>
          <Hero>
            <HeroBody>
              <TextHeadingWrapper>
                <H1 uistyle="brand160" text="Pricing" />
              </TextHeadingWrapper>

              <Text size="xl">
                When it comes to pricing, we believe it's fair to be transparent. Review the features below to decide between Essentials, Pro or Premium. And our handy calculator will help determine the cost of your chosen package.
              </Text>
            </HeroBody>
          </Hero>

          <TextHeadingWrapper>
            <Text text="Need help deciding?" size="xxl" />
          </TextHeadingWrapper>

          <ButtonBox>
            <Link to="/demo">
              <Button uistyle="primary" text="Request demo" size="lg" />
            </Link>
            <Link to="/contact">
              <Button uistyle="primary" text="Speak to the team" size="lg" ghost borderless />
            </Link>
          </ButtonBox>

          <ContentWrapper>
          <TextHeadingWrapper>
            <H2 uistyle="brand160" text="Calculate your price" />
          </TextHeadingWrapper>

          <Calculator>
            <PriceComponents>
              <PriceComponent>
                <H3 text="How many sites?" uistyle="brand160" />

                <PriceConfigurable>
                  <SpinnerButton icon={{ prefix: 'fas', name: 'minus', size: 'md' }} onClick={this.decrementValue('sites')} />
                  <BorderlessInput
                    type="number"
                    name="sites"
                    value={sites}
                    onChange={this.onSitesChange}
                    onFocus={this.handleFocus}
                  />
                  <SpinnerButton icon={{ prefix: 'fas', name: 'plus', size: 'md' }} onClick={this.incrementValue('sites')} />
                </PriceConfigurable>

                <Text text="Your total number of sites" size="xl" />
              </PriceComponent>

              <PriceComponent>
                <H3 text="How many people?" uistyle="brand160" />

                <PriceConfigurable>
                  <SpinnerButton icon={{ prefix: 'fas', name: 'minus', size: 'md' }} onClick={this.decrementValue('people')} />
                  <BorderlessInput
                    type="number"
                    name="people"
                    value={people}
                    onChange={this.onPeopleChange}
                    onFocus={this.handleFocus}
                  />
                  <SpinnerButton icon={{ prefix: 'fas', name: 'plus', size: 'md' }} onClick={this.incrementValue('people')} />
                </PriceConfigurable>

                <Text text="Your total number of employees" size="xl" />
              </PriceComponent>
            </PriceComponents>
            <PriceBreakdown>Each site includes 15 people. More can be added for {this.formatAsSterling(pricePerPersonEssentials)} (Essentials) or {this.formatAsSterling(pricePerPersonPro)} (Pro)</PriceBreakdown>
          </Calculator>

          <PackageContainer>
            <Package>
              <PackageTitle>Essentials</PackageTitle>

              {outOfScope ? (
                <>
                  <Price>Let's chat</Price>
                  <PriceSubText>custom pricing</PriceSubText>
                </>
              ) : (
                <>
                  <Price>{priceEssentials}</Price>
                  <PriceSubText>per month <sup>+ setup fee</sup></PriceSubText>
                </>
              )}

              <PackageItemsColumn>
                <PricingPackageFeature
                  Icon={SchedulingIcon}
                  titleCopy="Rota scheduling"
                  bodyCopy="Perfect staff rotas, every time"
                  size="xxl"
                />

                <PricingPackageFeature
                  Icon={HrIcon}
                  titleCopy="HR"
                  bodyCopy="Employee management at your fingertips"
                  size="xxl"
                />

                <PricingPackageFeature
                  Icon={TimeAttendanceIcon}
                  titleCopy="Time & attendance"
                  bodyCopy="Time and attendance monitoring made simple"
                  size="xxl"
                />

                <PricingPackageFeature
                  Icon={PayrollIcon}
                  titleCopy="Payroll"
                  bodyCopy="Perfect payroll, every time"
                  size="xxl"
                />

                <PricingPackageFeature
                  Icon={IntegrationsIcon}
                  titleCopy="Integrations"
                  bodyCopy="Meet our real-time partners"
                  size="xxl"
                />
              </PackageItemsColumn>
            </Package>

            <Package>
              <PackageHighlightLarge><Text uistyle="white">Most popular</Text></PackageHighlightLarge>
              <PackageTitle>Pro</PackageTitle>

              {outOfScope ? (
                <>
                  <Price>Let's chat</Price>
                  <PriceSubText>custom pricing</PriceSubText>
                </>
              ) : (
                <>
                  <Price>{pricePro}</Price>
                  <PriceSubText>per month <sup>+ setup fee</sup></PriceSubText>
                </>
              )}

              <PackageItemsColumn>
                <PricingPackageFeature
                  titleCopy="All Essentials features, plus"
                  highlighted={true}
                />

                <PricingPackageFeature titleCopy="Messaging" />
                <PricingPackageFeature titleCopy="Notable events" />
                <PricingPackageFeature
                  titleCopy="Document e-signing"
                  bodyCopy="including 50 credits every month"
                />
                <PricingPackageFeature titleCopy="Employee self-onboarding" />
                <PricingPackageFeature titleCopy="Asset registry" />
                <PricingPackageFeature titleCopy="Benefits tracker" />
                <PricingPackageFeature titleCopy="Custom metrics" />
              </PackageItemsColumn>
            </Package>

            <Package>
              <PackageTitle>Premium</PackageTitle>
              <Price>Let's chat</Price>
              <PriceSubText>custom pricing</PriceSubText>

              <PackageItemsColumn>
                <PricingPackageFeature
                  titleCopy="All Essentials and Pro features, plus"
                  highlighted={true}
                />

                <PricingPackageFeature titleCopy="Demand forecasting" />
                <PricingPackageFeature titleCopy="SSO" />
                <PricingPackageFeature titleCopy="Multi currency/timezone" />
                <PricingPackageFeature titleCopy="API access" />
              </PackageItemsColumn>
            </Package>
          </PackageContainer>

          <PackageContainer>
            <Package>
              <PackageHighlightSmall><Text uistyle="white">Boosters</Text></PackageHighlightSmall>
              <PackageText>Add boosters onto any package</PackageText>

              <PackageItemsRow>
                <PricingPackageFeature
                  titleCopy="Tronc"
                  bodyCopy="&pound;10 per site per month"
                />
              </PackageItemsRow>
            </Package>
          </PackageContainer>
          </ContentWrapper>
        </ResponsiveContainer>

        <ResponsiveContainer>
          <TextHeadingWrapper>
            <H2 text="Compare plans" uistyle="brand160" />
          </TextHeadingWrapper>

          <ComparisonTableWrapper>
          <ComparisonTable>
            <thead>
            <tr>
              <th></th>
              <th><H3 uistyle="brand160">Essentials</H3></th>
              <th><H3 uistyle="brand160">Pro</H3></th>
              <th><H3 uistyle="brand160">Premium</H3></th>
            </tr>
            </thead>
            <tbody>
            {featureComparison.map((item) => (
              <>
                <HeaderRow>
                  <td><H3 uistyle="brand160" weight="bold">{item.category}</H3></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </HeaderRow>
                {item.features.map((feature, index) => (
                  <tr className={index === item.features.length - 1 ? '' : 'bordered'}>
                    <td><CellText text={feature.label}/></td>
                    <td>{!feature.byPackage || feature.byPackage[0] ? (<CheckmarkIcon/>) : (<></>)}</td>
                    <td>{!feature.byPackage || feature.byPackage[1] ? (<CheckmarkIcon/>) : (<></>)}</td>
                    <td>{!feature.byPackage || feature.byPackage[2] ? (<CheckmarkIcon/>) : (<></>)}</td>
                  </tr>
                ))}
              </>
            ))}
            </tbody>
          </ComparisonTable>
          </ComparisonTableWrapper>

          <TextHeadingWrapper>
            <H2 text="Set-up fee includes" uistyle="brand160" />
          </TextHeadingWrapper>

          <SetupFeeContainer>
            <SetupFeeItem>
              <SetupFeeItemIcon><TrainingSessionIcon /></SetupFeeItemIcon>
              <SetupFeeItemText>One training session</SetupFeeItemText>
            </SetupFeeItem>

            <SetupFeeItem>
              <SetupFeeItemIcon><AnnualReviewIcon /></SetupFeeItemIcon>
              <SetupFeeItemText>An annual review</SetupFeeItemText>
            </SetupFeeItem>

            <SetupFeeItem>
              <SetupFeeItemIcon><FullSetupIcon /></SetupFeeItemIcon>
              <SetupFeeItemText>Full set-up of Rotaready</SetupFeeItemText>
            </SetupFeeItem>

            <SetupFeeItem>
              <SetupFeeItemIcon><ConsultatonIcon /></SetupFeeItemIcon>
              <SetupFeeItemText>One consultation</SetupFeeItemText>
            </SetupFeeItem>
          </SetupFeeContainer>
        </ResponsiveContainer>

        <ResponsiveContainer>
          <ContentWrapper>
            <TextHeadingWrapper>
              <H2 text="Any questions?" uistyle="brand160" />
            </TextHeadingWrapper>

            <TextHeadingWrapper>
              <Text text="Check out our FAQ page" size="xl" />
            </TextHeadingWrapper>

            <CenteredWrapper>
              <Link to="/faq">
                <Button uistyle="primary" text="FAQs" size="lg" />
              </Link>
            </CenteredWrapper>
          </ContentWrapper>
        </ResponsiveContainer>
      </Body>
    );
  }
}

export default PricingPage;
